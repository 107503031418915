<script setup lang="ts">
import { useFluent } from 'fluent-vue'
import type { MenuItem } from '~/types'

const { $t } = useFluent()

const props = defineProps<{
  modelValue: string
}>()
const { modelValue } = toRefs(props)
const emit = defineEmits(['update:modelValue'])

const types = [
  {
    value: 'public',
    icon: 'icon-globe',
  },
  {
    value: 'unlisted',
    icon: 'icon-lock-unlock',
  },
  {
    value: 'private',
    icon: 'icon-lock',
  },
  {
    value: 'direct',
    icon: 'icon-at',
  },
]
const statusVisibilities: MenuItem<string>[] = types.map(type => ({
  meta: type.value,
  icon: type.icon,
  label: $t(`visibility-${type.value}`),
  desc: $t(`visibility-${type.value}_desc`),
  command: () => emit('update:modelValue', type.value),
}))

const currentVisibility = computed(() => statusVisibilities.find(v => v.meta === modelValue.value) || statusVisibilities[0])
</script>

<template>
  <UiDropdownSelect
    :options="statusVisibilities">
    <template #title>
      <span :class="[currentVisibility.icon]" />
    </template>
  </UiDropdownSelect>
</template>

<script setup lang="ts">
import type { Editor } from '@tiptap/vue-3'
import { BubbleMenu } from '@tiptap/vue-3'

const props = defineProps<{ editor: Editor }>()

interface BubbleMenuItem {
  name: string
  icon?: string
  component?: Component
  isActive?: () => boolean
  command?: () => void
}

const isActiveMediaViewer = computed(() => {
  return props.editor?.isActive('media-viewer') || props.editor?.isActive('embed')
})
// function toggleViewerType () {
//   const type = props.editor.view.state.selection.node?.attrs.type === 'grid' ? 'slideshow' : 'grid'
//   props.editor?.chain().focus().setViewerType(type).run()
// }

const items: ComputedRef<BubbleMenuItem[]> = computed(() => {
  return isActiveMediaViewer.value
    ? []
    : [
        {
          name: 'bold',
          isActive: () => props.editor.isActive('bold') ?? false,
          command: () => props.editor.chain().focus().toggleBold().run(),
          icon: 'icon-format-bold',
        },
        {
          name: 'italic',
          isActive: () => props.editor.isActive('italic') ?? false,
          command: () => props.editor.chain().focus().toggleItalic().run(),
          icon: 'icon-format-italic',
        },
        {
          name: 'underline',
          isActive: () => props.editor.isActive('underline') ?? false,
          command: () => props.editor.chain().focus().toggleUnderline().run(),
          icon: 'icon-format-underline',
        },
        {
          name: 'strike',
          isActive: () => props.editor.isActive('strike') ?? false,
          command: () => props.editor.chain().focus().toggleStrike().run(),
          icon: 'icon-format-strikethrough',
        },
      ]
})
</script>

<template>
  <BubbleMenu
    v-if="items.length"
    :editor="editor"
    :tippy-options="{ ...tippyOptions(), arrow: true }">
    <ul class="bubble-menu menu-list">
      <li v-for="item, index of items" :key="index" :class="{ active: item.isActive?.() }">
        <Component :is="item.component" v-if="item.component" :editor="props.editor" />
        <button v-else class="compact" :class="{ focused: item.isActive?.() }" @click="item.command">
          <span :class="[item.icon]" />
        </button>
      </li>
    </ul>
  </BubbleMenu>
</template>

<style lang="scss" scoped>
ul.bubble-menu {
  --size: var(--base-size); //calc(var(--base-size) / 6 * 5);
  display: flex;
  align-items: center;
  // adding: var(--padding-mini);
  height: var(--size);
  li {
    justify-content: center;
    padding-inline: 0;
    height: 100%;
    min-width: var(--size);
    min-height: unset;
    --r: calc(var(--corner-radius) * 0.8);
    &:first-child {
    //   border-top-left-radius: var(--r);
    //   border-bottom-left-radius: var(--r);
    }
    &:last-child {
    //   border-top-right-radius: var(--r);
    //   border-bottom-right-radius: var(--r);
    }
    & > * {
      height: 100%;
    }
    button {
      height: 100%;
      aspect-ratio: 1;
      ::after {
        content: none !important;
      }
    }
    &.active {
      // background: var(--color-accent);
      //   border: 2px solid var(--color-line);
      // }
    }
  }
}
</style>

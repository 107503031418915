<script setup lang="ts">
import { nodeViewProps, NodeViewWrapper } from '@tiptap/vue-3'
import type { mastodon } from '#types'

const props = defineProps(nodeViewProps)

const isLoading = ref(false)
const card = ref<mastodon.v1.PreviewCard | undefined>(undefined)

onMounted(async () => {
  try {
    isLoading.value = true
    const url = props.node.attrs['data-embed-url']
    card.value = await $fetch<mastodon.v1.PreviewCard>('/api/microformats', {
      method: 'POST',
      body: { url },
    })
    /* DEBUG */
    console.log(`%c %c card.value: `, 'background:#ffbb00;color:#000', 'color:#00aaff', card.value)
  }
  catch (err) {
    console.error(err)
    props.deleteNode()
  }
  finally {
    isLoading.value = false
  }
})
</script>

<template>
  <NodeViewWrapper>
    <p v-if="isLoading">
      {{ props.node.attrs['data-embed-url'] }}  loading...
    </p>
    <a v-if="card" :href="card.url" target="_blank" class="embed-link">
      <img v-if="card.image" :src="card.image">
      <p>{{ card.url }}</p>
      <h3 v-if="card.title">{{ card.title }}</h3>
      <p v-if="card.description">{{ card.description }}</p>
    </a>
    <button type="button" @click="deleteNode">
      x
    </button>
  </NodeViewWrapper>
</template>

<style scoped>
  .embed-link {
    display: inline-block;
    text-decoration: none;
    font-size: 80%;
    background-color: #DFDFDF;
    color: black;
    border-radius: var(--corner-radius);
    overflow: clip;

    & > :not(img) {
      padding-inline: var(--padding-small);
      &:last-child {
        padding-bottom: var(--padding-small);
      }
    }

    h3, p {
      margin: 0;
    }
  }
</style>

import { Node, mergeAttributes, VueNodeViewRenderer } from '@tiptap/vue-3'
import { Plugin, PluginKey } from '@tiptap/pm/state'
import { find } from 'linkifyjs'
import TiptapViewsEmbed from '~/components/tiptap/views/TiptapViewsEmbed.vue'

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    embed: {
      setEmbed: (link: string) => ReturnType
    }
  }
}

const Embed = Node.create<{ HTMLAttributes: Record<string, unknown> }>({
  name: 'embed',
  group: 'block',
  draggable: true,
  atom: true,

  addOptions() {
    return {
      HTMLAttributes: {},
    }
  },

  parseHTML() {
    return [
      {
        tag: 'div[data-embed-url]',
      },
    ]
  },

  addAttributes() {
    return {
      'data-embed-url': {
        default: null,
      },
    }
  },

  renderHTML({ HTMLAttributes }) {
    return ['div', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0]
  },

  addNodeView() {
    return VueNodeViewRenderer(TiptapViewsEmbed)
  },

  addCommands() {
    return {
      setEmbed: (link: string) => ({ editor, commands: { insertContent, updateAttributes } }) => {
        if (editor.isActive(this.name)) {
          return updateAttributes(this.name, { 'data-embed-url': link })
        }
        else {
          return insertContent({
            type: this.name,
            attrs: { 'data-embed-url': link },
          })
        }
      },
    }
  },

  addProseMirrorPlugins() {
    return [
      new Plugin({
        key: new PluginKey('handlePasteLink'),
        props: {
          handlePaste: (_view, _event, slice) => {
            let textContent = ''
            slice.content.forEach((node) => {
              textContent += node.textContent
            })
            if (!textContent) {
              return false
            }

            const link = find(textContent).find(item => item.isLink && item.value === textContent)
            if (!link) {
              return false
            }

            this.editor.commands.setEmbed(link.href)
            return true
          },
        },
      }),
    ]
  },
})

export default Embed
